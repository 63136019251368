<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ 'show': mqShallShowLeftSidebar }">
        <!-- Filters' Card -->
        <b-card>

          <!-- pages -->
          <div class="filter-title top-filter">
            <h6>
              Mois
            </h6>
            <b-form-radio-group v-model="localFilters.month" class="categories-radio-group" stacked :disabled="showLoading"
              :options="filterOptions.months" @change="$emit('triggerFilter', localFilters.month)" />
          </div>

        </b-card>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localFilters: { ...this.filters }
    }
  },
  methods: {
    triggerFilter() {
      this.$emit('triggerFilter', this.localFilters);
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep>.custom-control {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 769px) {
  .top-filter {
    padding-top: 2rem;
    /* Adjust the margin-top value as needed */
  }
}
</style>

<template>
    <div id="products-list">

        <div id="printProductsList" v-show="false">
            <div class="invoice-box" style="background-color: white; width:100%">
                <div style="border: 1px solid black;text-align: center ;font-size: large ; margin-bottom: 10px ">
                    <h2> Liste des articles</h2>
                </div>
                <!-- <div style="text-align: center;font-size: medium">
                    <h3> {{ dateDebut }} .. {{ dateFin }}</h3>
                </div> -->
                <table border="1" cellpadding="1" cellspacing="0">
                    <tr class="heading">
                        <td class="bordered" style="text-align: left;width:20% ;font-size: medium">
                            Code
                        </td>
                        <td class="bordered" style="text-align: left;width:55% ;font-size: medium">
                            Désignation
                        </td>
                        <td class="bordered" style="text-align: center;width:15% ;font-size: medium">
                            D.L.C
                        </td>
                        <td class="bordered" style="text-align: right;width:10% ;font-size: medium">
                            Stock
                        </td>
                    </tr>

                    <tr class="item" :key="indextr" v-for="(tr, indextr) in rowData">
                        <td class="bordered"
                            style="text-align: left;width:20%;font-size: left;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Item_No }}
                        </td>
                        <td class="bordered"
                            style="text-align: left;width:55%;font-size: left;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Description }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:15%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.DLC }}
                        </td>
                        <td class="bordered"
                            style="text-align: right;width:10%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{
                                tr.Sales_Inventory
                            }}
                        </td>
                    </tr>

                </table>

                <br>

                <div v-if="activeFilter != null">
                    <strong>Filtré par : </strong>
                    {{ this.activeFilter }}
                </div>

            </div>
        </div>

        <div id="printItemLedgerEntryList" v-show="false">
            <div class="invoice-box" style="background-color: white; width:100%">
                <div style="border: 1px solid black;text-align: center ;font-size: large ; margin-bottom: 10px ">
                    <h4> Détails des transactions de l'article {{ selectedProduct.Description }}</h4>
                </div>
                <table border="1" cellpadding="1" cellspacing="0">
                    <tr class="heading">
                        <td class="bordered" style="text-align: left;width:25% ;font-size: medium">
                            Mois
                        </td>
                        <td class="bordered" style="text-align: left;width:25% ;font-size: medium">
                            Type
                        </td>
                        <td class="bordered" style="text-align: left;width:25% ;font-size: medium">
                            Quantité
                        </td>
                        <td class="bordered" style="text-align: left;width:25% ;font-size: medium">
                            Montant
                        </td>
                    </tr>

                    <tr class="item" :key="indextr" v-for="(tr, indextr) in ItemLedgerEntry">
                        <td class="bordered"
                            style="text-align: left;width:25%;font-size: left;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Month }}
                        </td>
                        <td class="bordered"
                            style="text-align: left;width:25%;font-size: left;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Entry_Type }}
                        </td>
                        <td class="bordered"
                            style="text-align: left;width:25%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Sum_Quantity }}
                        </td>
                        <td class="bordered"
                            style="text-align: left;width:25%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{
                                tr.Amount
                            }}
                        </td>
                    </tr>

                </table>

                <br>

                <div>
                    <strong>Filtré par : </strong>
                    Mois : {{ this.selectedDateModal == null ? "Tous" : this.selectedDateModal }}
                    , Type : {{ this.selectedType == null ? "Tous" : this.selectedType }}
                </div>

            </div>
        </div>

        <b-modal ref="my-modal" scrollable :title="'Statistique de l\'article : ' + selectedProduct.Description" hide-footer
            size="xl" cancel-variant="outline-secondary">

            <b-row>
                <b-col md="6" lg="4">
                    <label for="recherche" style="margin-bottom: 5px;">Date</label>
                    <b-form-select v-model="selectedDateModal" :options="listeDates" size="sm" class="mb-2">
                    </b-form-select>
                </b-col>

                <b-col md="6" lg="4">
                    <label for="recherche" style="margin-bottom: 5px;">Type</label>
                    <b-form-select v-model="selectedType" :options="listeTypes" size="sm" class="mb-2">
                    </b-form-select>
                </b-col>

                <b-col md="12" lg="4">
                    <b-button style="float: right;    margin: 4px;" class="w-full mb-2 mt-2" @click="StatClick"
                        variant="outline-primary">
                        <feather-icon icon="PieChartIcon" />
                    </b-button>
                    <b-button style="float: right;    margin: 4px;" class="w-full mb-2 mt-2" @click="printStat"
                        variant="outline-success">
                        <feather-icon icon="PrinterIcon" />
                    </b-button>
                    <b-button style="float: right;    margin: 4px;" class="w-full mb-2 mt-2" @click="exportStat"
                        variant="outline-warning">
                        <feather-icon icon="DownloadIcon" />
                    </b-button>
                </b-col>
            </b-row>

            <b-table fixed responsive :bordered="true" :small="true" :no-border-collapse="true" :items="ItemLedgerEntry"
                :fields="fields">
            </b-table>



        </b-modal>
        <b-overlay :show="showLoading" spinner-variant="primary">

            <!-- <section id="ecommerce-header">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="ecommerce-header-items">
                            <div class="result-toggler">
                                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                                    @click="mqShallShowLeftSidebar = true" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
            <section id="ecommerce-header">

                <b-row>
                    <b-col cols="2">
                        <div class="ecommerce-header-items">
                            <div class="result-toggler">
                                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                                    @click="mqShallShowLeftSidebar = true" />
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <h2 class="d-block d-lg-none">Liste des articles</h2>
                    </b-col>

                </b-row>
            </section>

            <section>
                <b-card>
                    <ag-grid :agGrid="agGrid" :pageTitle="isMobileDevice() ? '' : 'Liste des articles'"
                        @refresh="loadData" />
                </b-card>
            </section>

            <!-- Sidebar -->
            <portal to="content-renderer-sidebar-detached-left">
                <ItemsLeftFilterSidebar :filters="filters" :filter-options="filterOptions" :showLoading="showLoading"
                    :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" @triggerFilter="handleEventFilter" />
            </portal>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import printDocs from "@/utils/printDocs";
import ItemsLeftFilterSidebar from "./ItemsLeftFilterSidebar.vue";
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

export default {
    components: {
        AgGrid,
        ItemsLeftFilterSidebar
    },
    data() {
        return {
            selectedProduct: {
                Description: ""
            },
            filterOptions: {
                months: [
                ],
            },
            filters: {
                month: '',
            },
            allRows: [],
            allData: [],
            headerTitle: ["N°", "Designation", "D.L.C", "Stock"],
            headerVal: ["No", "Description", "DLC", "Sales_Inventory"],
            headerTitleModal: ["Mois", "Type", "Quantité", "Montant"],
            headerValModal: ["Month", "Entry_Type", "Sum_Quantity", "Amount"],
            listeDates: [],
            selectedDateModal: null,
            selectedType: null,
            listeTypes: [],
            ItemLedgerEntry: [],
            fields: [{
                label: 'Mois',
                key: 'Month',
                width: '80px',
                sortable: true
            },
            {
                label: 'Type',
                key: 'Entry_Type',
                width: '120px',
                sortable: true
            },
            {
                label: 'Quantité',
                key: 'Sum_Quantity',
                width: '80px',
                sortable: true
            }, {
                label: 'Montant',
                key: 'Amount',
                width: '80px',
                sortable: true
            }
            ],
            rowData: [],
            activeFilter: null,
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Mois",
                        field: "Month",
                    },
                    {
                        headerName: "Description",
                        field: "Description",
                    },
                    {
                        headerName: "N°",
                        field: "Item_No",
                    },
                    {
                        headerName: "Stock départ",
                        field: "Starting_Inventory",
                    },
                    {
                        headerName: "Stock Fin",
                        field: "Ending_Inventory",
                    },
                    {
                        headerName: "Qte Achetée",
                        field: "Purchased_Quantity",
                    },
                    {
                        headerName: "Qte Vendue",
                        field: "Sales_Quantity",
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, icon: "query_stats" },
                        headerName: '',
                        width: 100
                    },
                ],
                rows: []
            },
        }
    },
    setup() {
        const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
        return {
            mqShallShowLeftSidebar,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    watch: {
        selectedDateModal(val) {
            if (val == null)
                return;
            this.showLoading = true;
            this.ItemLedgerEntry = [];
            this.allData.forEach(element => {
                if (element.Item_No == this.selectedProduct.Item_No) {
                    if (val == "Tous" || element.Month == val) {
                        if (this.selectedType == null || this.selectedType == "Tous" || this.selectedType == element.Entry_Type) {
                            if (element.Entry_Type == "Sale")
                                element["Amount"] = element.Sum_Sales_Amount_Expected;
                            else
                                element["Amount"] = element.Sum_Purchase_Amount_Expected;
                            this.ItemLedgerEntry.push(element);
                        }
                    }
                }
            });
            this.showLoading = false;
        },
        selectedType(val) {
            if (val == null)
                return;
            this.showLoading = true;
            this.ItemLedgerEntry = [];
            this.allData.forEach(element => {
                if (element.Item_No == this.selectedProduct.Item_No) {
                    if (val == "Tous" || element.Entry_Type == val) {
                        if (this.selectedDateModal == null || this.selectedDateModal == "Tous" || this.selectedDateModal == element.Month) {
                            if (element.Entry_Type == "Sale")
                                element["Amount"] = element.Sum_Sales_Amount_Expected;
                            else
                                element["Amount"] = element.Sum_Purchase_Amount_Expected;
                            this.ItemLedgerEntry.push(element);
                        }
                    }
                }
            });
            this.showLoading = false;
        }
    },
    methods: {
        isMobileDevice() {
            return window.innerWidth <= 769;
        },
        async handleEventFilter(data) {
            this.mqShallShowLeftSidebar = false;
            this.showLoading = true;
            if (data == "Tous") {
                this.agGrid.rows = this.allRows;
            }
            else {
                this.agGrid.rows = this.allRows.filter(item => item.Month === data);
            }
            this.showLoading = false;
        },
        async showDetails(data) {
            this.$router.push("/supplier/item-statistics/" + data.Item_No);
            // this.showLoading = true;
            // this.ItemLedgerEntry = [];
            // this.selectedProduct = data;
            // this.selectedDateModal = null;
            // this.selectedType = null;
            // this.listeDates = [];
            // this.listeTypes = [];
            // let response = await this.$http.get("vendor_item_ledger_entry/findWhere", { params: { Item_No: "'" + data.Item_No + "'" } }).catch(() => this.showLoading = false);
            // this.allData = response.data;
            // this.allData.forEach(element => {
            //     if (element.Item_No == this.selectedProduct.Item_No) {
            //         if (element.Entry_Type == "Sale")
            //             element["Amount"] = element.Sum_Sales_Amount_Expected;
            //         else
            //             element["Amount"] = element.Sum_Purchase_Amount_Expected;
            //         this.ItemLedgerEntry.push(element);
            //         if (this.listeDates.indexOf(element.Month) == -1)
            //             this.listeDates.push(element.Month);
            //         if (this.listeTypes.indexOf(element.Entry_Type) == -1)
            //             this.listeTypes.push(element.Entry_Type);
            //     }
            // });
            // this.listeDates.push("Tous");
            // this.listeTypes.push("Tous");
            // this.$refs['my-modal'].show();
            // this.showLoading = false;
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("item_by_vendor");
            this.agGrid.rows = response.data;
            this.allRows = response.data;
            const uniqueMonths = new Set(response.data.map(item => item.Month));
            // Convert the Set back to an array if needed
            const distinctMonths = Array.from(uniqueMonths);
            distinctMonths.unshift("Tous");
            this.filters.month = "Tous";
            this.filterOptions.months = distinctMonths;
            this.showLoading = false;
        },
        StatClick() {
            this.$router.push("/supplier/product/" + this.selectedProduct.Item_No);
        },
        exportStat() {
            import('@/utils/ExportToExcel').then(excel => {
                const data = this.formatJson(this.headerValModal, this.ItemLedgerEntry)
                excel.export_json_to_excel({
                    header: this.headerTitleModal,
                    data,
                    filename: "Détails des transactions de l'article " + this.selectedProduct.Description,
                    autoWidth: true,
                    bookType: "xlsx"
                })
            })
        },
        exportExcel() {
            import('@/utils/ExportToExcel').then(excel => {
                const data = this.formatJson(this.headerVal, this.rows)
                excel.export_json_to_excel({
                    header: this.headerTitle,
                    data,
                    filename: 'Liste des articles',
                    autoWidth: true,
                    bookType: "xlsx"
                })
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                return v[j]
            }))
        },
        printList() {
            const model = this.gridApi.getFilterModel();
            let str = JSON.stringify(model);
            str = JSON.stringify(model, null, 4); // (Optional) beautiful indented output.
            this.activeFilter = str;
            this.rowDatac = [];
            this.gridApi.forEachNodeAfterFilter(node => {
                this.rowData.push(node.data);
            });
            this.$nextTick(function () {
                var contents = document.getElementById("printProductsList").innerHTML;
                printDocs.print(contents);
            });
        },
        printStat() {
            this.$nextTick(function () {
                var contents = document.getElementById("printItemLedgerEntryList").innerHTML;
                printDocs.print(contents);
            });
        },
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
